var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"cardPedido",class:{
      bwarning:
        _vm.pedido.pagamento || _vm.pedido.pagamento == '' || _vm.pedido.status == 2,
      binfo: _vm.pedido.status == 3,
      bsuccess: _vm.pedido.status == 4,
      bCancelado: _vm.pedido.excluido === 1,
      bdanger:
        _vm.pedido.status == 0 ||
        _vm.pedido.status == 1 ||
        _vm.pedido.pedido_erro == 1 ||
        _vm.pedido.excluido === 1,
    },on:{"click":function($event){return _vm.abrirPedido([_vm.pedido])}}},[_c('div',{staticClass:"senha"},[_c('div',[_vm._v(" "+_vm._s(_vm.pedido.senha)+" "),(_vm.pedido.sistema == 'IFOOD')?_c('img',{staticStyle:{"width":"40px"},attrs:{"src":require("@/assets/img/ifoodLogo.png")}}):_vm._e()]),_c('small',[_vm._v("# "+_vm._s(_vm.pedido.cod_pedido))]),_c('small',{staticClass:"font-weight-bold"},[_c('i',{staticClass:"fa fa-laptop"}),_vm._v(" "+_vm._s(_vm.pedido.caixa)+" "),(_vm.pedido.id_sistema && _vm.pedido.id_sistema != '')?_c('span',[_vm._v(" - "+_vm._s(_vm.pedido.id_sistema)+" "+_vm._s(_vm.pedido.sistema)+" ")]):_vm._e()])]),_c('div',{staticClass:"tipo_entrega"},[_vm._v(" "+_vm._s(_vm.pedido.tipo_entrega)+" "),_c('small',[_c('small',{class:{
            'text-warning':
              !_vm.pedido.pagamento ||
              _vm.pedido.pagamento == '' ||
              _vm.pedido.status == 2,
            'text-info': _vm.pedido.status == 3,
            'text-success': _vm.pedido.status == 4,
            'text-danger':
              _vm.pedido.status == 0 ||
              _vm.pedido.status == 1 ||
              _vm.pedido.pedido_erro == 1 ||
              _vm.pedido.excluido === 1,
          }},[(_vm.pedido.excluido === 1)?_c('div',[_c('b-icon-trash2'),_vm._v(" PEDIDO CANCELADO "),_c('small',[_vm._v(" "+_vm._s(_vm.pedido.usuario_excluiu)+" "+_vm._s(_vm._f("moment")(_vm.pedido.excluido_data,"DD/MM/YYYY - HH:mm"))+" ")])],1):_c('div',[_c('i',{staticClass:"fa fa-dot-circle",class:{
                'fa-exclamation-triangle': _vm.pedido.pedido_erro == 1,
              }}),_vm._v(" "+_vm._s(" ")+" "),(_vm.pedido.status == 0 || _vm.pedido.status == 1)?_c('span',[_vm._v("Á Confirmar")]):(_vm.pedido.pedido_erro == 1)?_c('span',[_vm._v("PROBLEMA NO PEDIDO")]):(
                !_vm.pedido.pagamento ||
                _vm.pedido.pagamento == '' ||
                _vm.pedido.status == 2
              )?_c('span',[_vm._v(" "+_vm._s(_vm.pedido.sistema != "INTERNO-WEB" ? "Pedido Confirmado" : "Pendente - Pagamento")+" ")]):(
                _vm.pedido.status == 3 && _vm.pedido.tipo_entrega == 'ENTREGA'
              )?_c('span',[_vm._v("Rota entrega")]):(
                _vm.pedido.status == 3 && _vm.pedido.tipo_entrega == 'BUSCAR'
              )?_c('span',[_vm._v("Pronto para Retirar")]):(_vm.pedido.status == 4)?_c('span',[_vm._v(" Concluído - Entregue ")]):_vm._e()])])]),(_vm.pedido.tempo_entrega && _vm.pedido.tempo_entrega != '')?_c('small',{staticStyle:{}},[_c('i',{staticClass:"fa fa-clock"}),_vm._v(" "+_vm._s(_vm.pedido.tempo_entrega))]):_vm._e(),_c('small',[_vm._v("Realizado ás "+_vm._s(_vm._f("moment")(_vm.pedido.data,"HH:mm")))]),(_vm.pedido.moto)?_c('small',[_c('i',{staticClass:"fa fa-motorcycle"}),_vm._v(" "+_vm._s(_vm.pedido.moto)+" ")]):_vm._e(),(_vm.pedido.usuario_cadastro)?_c('small',[_c('i',{staticClass:"fa fa-headset"}),_vm._v(" "+_vm._s(_vm.pedido.usuario_cadastro)+" ")]):_vm._e(),(_vm.pedido.agendamento)?_c('div',{staticClass:"bg-info text-white p-1"},[_c('b-icon-calendar-check'),_vm._v(" Agendado em "),_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(_vm._f("moment")(_vm.pedido.data,"DD/MM HH:mm")))]),_vm._v(" para ás "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.pedido.agendamento,"DD/MM/YYYY - HH:mm"))),_c('br'),_c('small',[_c('small',[_vm._v(_vm._s(_vm.pedido.agendamento_obs))])])],1):_vm._e()]),_c('div',{staticClass:"info"},[(_vm.pedido && _vm.pedido.sistema == 'ONLINE')?_c('div',{staticClass:"font-weight-bold"},[_c('b-icon-hdd-network'),_vm._v(" APP "+_vm._s(_vm.pedido.id_sistema)+" ")],1):_vm._e(),_c('i',{staticClass:"fa fa-user"}),_vm._v(" "+_vm._s(_vm.pedido.cliente.Nome)+" "),_c('small',{},[(_vm.pedido.cliente.Bairro && _vm.pedido.cliente.Bairro != '')?_c('span',[_c('i',{staticClass:"fa fa-map-marked"}),_vm._v(" "+_vm._s(_vm.pedido.cliente.Bairro))]):_vm._e(),(_vm.pedido.cliente.Telefone)?_c('div',[_c('i',{staticClass:"fa fa-phone"}),_vm._v(" "+_vm._s(_vm.pedido.cliente.Telefone)+" ")]):_vm._e()])]),_c('div',{staticClass:"valor"},[_c('small',[_vm._v(_vm._s(_vm.pedido.pagamento)+" "),(
            _vm.pedido.pagamento == 'Cartão' &&
            _vm.pedido.integration_data &&
            _vm.pedido.integration_data.payments &&
            _vm.pedido.integration_data.payments.methods &&
            _vm.pedido.integration_data.payments.methods.length > 0 &&
            _vm.pedido.integration_data.payments.methods[0].card &&
            _vm.pedido.integration_data.payments.methods[0].card.brand
          )?_c('div',[_c('b',[_vm._v("Bandeira: "+_vm._s(_vm.pedido.integration_data.payments.methods[0].card.brand))])]):_vm._e()]),_vm._v(" R$ "+_vm._s(_vm._f("currency")(_vm.pedido.vl_total))+" "),_c('small',[(
            _vm.pedido.pagamento == 'Dinheiro' &&
            _vm.pedido.integration_data &&
            _vm.pedido.integration_data.payments &&
            _vm.pedido.integration_data.payments.methods &&
            _vm.pedido.integration_data.payments.methods.length > 0 &&
            _vm.pedido.integration_data.payments.methods[0].cash &&
            _vm.pedido.integration_data.payments.methods[0].cash.changeFor
          )?_c('div',[_c('b',[_vm._v("Troco para: "+_vm._s(_vm._f("currency")(_vm.pedido.integration_data.payments.methods[0].cash.changeFor)))])]):_vm._e()]),(_vm.pedido.usuario_recebeu)?_c('small',[_c('small',[_vm._v(_vm._s(_vm.pedido.usuario_recebeu ? _vm.pedido.usuario_recebeu.nome : "")+" "),_c('small',[_vm._v(" Caixa "+_vm._s(_vm.pedido.cod_caixa)+" ")])])]):_vm._e(),(!_vm.pedido.pagamento || _vm.pedido.pagamento == '')?_c('div',[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.receber(_vm.pedido)}}},[_c('i',{staticClass:"fa fa-money-bill-alt"}),_vm._v(" Receber ")])],1):_vm._e(),(
          (_vm.pedido.sistema == 'ONPEDIDO' || _vm.pedido.sistema == 'IFOOD') &&
          _vm.pedido.status == 0
        )?_c('div',[_c('b-button',{attrs:{"variant":"info"},on:{"click":function($event){return _vm.confirmarPedido(_vm.pedido)}}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" CONFIRMAR PEDIDO")])],1):_vm._e(),(
          _vm.pedido.sistema == 'IFOOD' &&
          _vm.pedido.tipo_entrega == 'BUSCAR' &&
          _vm.pedido.status == 2
        )?_c('div',[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.confirmarPronto(_vm.pedido)}}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" MARCAR COMO PRONTO")])],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }