<template>
  <div class="conainer">
    <div
      v-if="loading"
      class="text-center p-4"
    >
      <b-spinner label="Carregando..." />
    </div>
    <div
      v-if="!loading"
      class="row"
    >
      <div
        v-if="!loading"
        class="col-12 col-md-6"
        style="border-right: 1px solid #dedede"
      >
        <div
          class="row text-"
          v-if="pedidoObj && pedidoObj.cod_pedido"
          style="font-size: 0.8rem"
        >
          <div class="col-6 col-sm-3 pt-3">
            <b>Cod. Pedido</b><br />
            {{ pedidoObj.cod_pedido }}
          </div>
          <div class="col-6 col-sm-4 pt-3">
            <b>Senha / Nº</b><br />
            {{ pedidoObj.senha }}
          </div>
          <div class="col-6 col-sm-5 pt-3">
            <b>Data / Hora</b><br />
            {{ pedidoObj.data | moment("DD/MM/YYYY HH:mm:ss") }}
          </div>
          <div class="col-6 col-sm-3 text-left pt-3">
            <b>Tipo Entrega</b><br />
            {{ pedidoObj.tipo_entrega }}
          </div>
          <div class="col-12 col-sm-9 text-left pt-3">
            <b>Cliente</b><br />
            <span v-if="pedidoObj.cliente && pedidoObj.cliente.Nome">
              {{ pedidoObj.cliente.Nome }} - {{ pedidoObj.cliente.Telefone }}
            </span>
            <span v-else> NÃO INFORMADO </span>
          </div>
          <div
            class="col-12 bg-light text-center d-flex justify-content-around p-3 mt-3"
          >
            <div>
              <b>Subtotal</b><br />
              {{ pedidoObj.vl_subtotal | currencyMask }}
            </div>
            <div>
              <b>Desconto</b><br />
              {{ pedidoObj.vl_desconto | currencyMask }}
            </div>
            <div>
              <b>Entrega</b><br />
              {{ pedidoObj.vl_entrega | currencyMask }}
            </div>
            <div>
              <b>Total</b><br />
              {{ pedidoObj.vl_total | currencyMask }}
            </div>
          </div>
        </div>

        <!-------SE MESA----------->
        <div
          class="row text-"
          v-if="mesaObj"
          style="font-size: 0.8rem"
        >
          <div class="col-6 col-sm-4 pt-3">
            <b>Cod. Conta</b><br />
            {{ mesaObj.id }}
          </div>

          <div class="col-6 col-sm-4 pt-3">
            <b>Data / Hora</b><br />
            {{ mesaObj.dt_aberta | moment("DD/MM/YYYY HH:mm:ss") }}
          </div>
          <div class="col-6 col-sm-4 pt-3">
            <b>Mesa</b><br />
            {{ mesaObj.mesa }}
          </div>

          <div
            class="col-12 bg-light text-center d-flex justify-content-around p-3 mt-3"
          >
            <div>
              <b>Subtotal</b><br />
              {{ mesaObj.vl_subtotal | currencyMask }}
            </div>
            <div>
              <b>Desconto</b><br />
              {{ mesaObj.vl_desconto | currencyMask }}
            </div>
            <div>
              <b>Taxa Serviço</b><br />
              {{ mesaObj.vl_taxa_servico | currencyMask }}
            </div>
            <div>
              <b>Total</b><br />
              {{ mesaObj.vl_total | currencyMask }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <hr />
          </div>
          <div
            class="col-12 mt-2 mb-2"
            v-if="notaInfo"
          >
            <div class="row">
              <div class="col-6 col-sm-3 text-center mb-2">
                <b>Id. Nota</b> <br />
                {{ notaInfo.id }}
              </div>
              <div class="col-6 col-sm-3 text-center mb-2">
                <b>Solicitada</b> <br />
                {{ notaInfo.data | moment("DD/MM/YYYY") }}
              </div>
              <div class="col-6 col-sm-3 text-center mb-2">
                <b>Número</b> <br />
                {{ notaInfo.numero }}
              </div>
              <div class="col-6 col-sm-3 text-center mb-2">
                <b>Integração</b> <br />
                {{ notaInfo.integracao }}
              </div>
              <div class="col-6 col-sm-3 text-center mb-2">
                <b>Status</b> <br />
                {{ notaInfo.status }}
              </div>
              <div class="col-6 col-sm-3 text-center mb-2">
                <b>Envio</b> <br />
                {{ notaInfo.data_envio | moment("DD/MM/YYYY HH:mm") }}
              </div>
              <div class="col-6 col-sm-3 text-center mb-2">
                <b>Processada</b> <br />
                {{ notaInfo.data_aprovado | moment("DD/MM/YYYY HH:mm") }}
              </div>
              <div class="col-6 col-sm-3 text-center mb-2">
                <b>Total</b> <br />
                {{ notaInfo.valor_total | currency }}
              </div>
            </div>

            <b-card
              class="text-center py-2"
              v-if="
                notaInfo &&
                notaInfo.adicional &&
                notaInfo.adicional.result &&
                notaInfo.adicional.result.mensagem
              "
            >
              {{ notaInfo.adicional.result.mensagem }}
            </b-card>
            <div
              class="row"
              v-if="notaInfo.status == 'Concluido'"
            >
              <div class="col-6 col-sm-6 text-center">
                <b>Chave</b> <br />
                {{ notaInfo.nota_chave }}
              </div>
              <div class="col-6 col-sm-6 text-center">
                <b>Protocolo</b> <br />
                {{ notaInfo.protocolo }}
              </div>
            </div>
            <hr />
            <div
              v-if="
                notaInfo &&
                notaInfo.adicional &&
                notaInfo.adicional.emissao &&
                !notaInfo.adicional.emissao.success
              "
            >
              <b>Error log</b><br />
              {{ notaInfo.adicional.emissao }}
              <hr />
            </div>
          </div>
          <div class="col-12">
            <div
              v-if="!notaInfo || notaInfo.status != 'Concluido'"
              class="text-center"
            >
              <h4>Cupom fiscal não emitido ainda.</h4>
              <b-btn
                variant="success"
                @click="confirmarCliente"
                :disabled="emitindo"
                size="lg"
              >
                <b-icon-file-binary /> Emitir cupom fiscal
              </b-btn>
            </div>
            <div
              v-if="notaInfo && notaInfo.status == 'Concluido'"
              class="text-center"
            >
              <h4 class="mb-4"><b-icon-check-circle /> Emitida com sucesso!</h4>
              <b-btn
                variant="success"
                block
                size="lg"
                @click="openDanfe"
                ><b-icon-printer /> Danfe</b-btn
              >
              <b-btn
                variant="warning"
                block
                class="ml-1"
                size="lg"
                @click="openSendWpp"
                :disabled="enviandoWpp"
              >
                <b-spinner
                  small
                  size="sm"
                  v-if="enviandoWpp"
                />
                <i class="fab fa-whatsapp" /> Enviar Whatsapp</b-btn
              >

              <b-btn
                v-if="user.nivel <= 0"
                variant="secondary"
                block
                class="ml-1"
                size="lg"
                @click="desnvincularNota"
                ><i class="fa fa-times" /> Desvincular Nota da Venda</b-btn
              >
              <b-btn
                variant="info"
                block
                size="lg"
                @click="donwloadXML"
                ><b-icon-code /> XML</b-btn
              >
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!loading"
        class="col-12 col-md-6"
      >
        <!-- <h5 class="text-center bg-light py-3">Itens da Nota</h5>
        <hr />
        <div
          v-if="
            notaInfo &&
            (!notaInfo.adicional ||
              !notaInfo.adicional.itens ||
              notaInfo.adicional.itens.length == 0)
          "
        >
          <h4 class="text-center">Nenhum item encontrado ou NFCe versão anterior</h4>
        </div> -->
        <iframe
          frameborder="0"
          v-if="
            notaInfo && notaInfo.status == 'Concluido' && notaInfo.danfeBASE64
          "
          :src="notaInfo.danfeBASE64"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
    <b-modal
      id="modal-cliente"
      title="Confirmação Cliente"
      hide-footer
    >
      <div class="text-center">
        <h4>Informar cliente?</h4>
        <b-radio-group
          v-model="semCliente"
          :options="[
            { text: 'Sim', value: false },
            { text: 'Não', value: true },
          ]"
        />
      </div>
      <hr />
      <ClienteForm
        :Cliente="pedidoObj && pedidoObj.cliente ? pedidoObj.cliente : {}"
        v-if="!semCliente"
        :tipo_entrega="{ titulo: 'ENTREGA' }"
        @ch="changeCliente"
      />
      <div class="text-center pt-4">
        <hr />
        <b-alert
          :show="result.show"
          :variant="result.success === true ? 'success' : 'danger'"
        >
          <div v-html="result.message"></div>
        </b-alert>
        <b-btn
          variant="success"
          class="mr-2"
          @click="emitir"
          :disabled="emitindo"
        >
          <span v-if="!emitindo">
            <b-icon-file-binary /> Emitir cupom fiscal
          </span>
          <span v-if="emitindo"> <b-spinner /> aguarde... </span>
        </b-btn>
        <b-btn
          variant="secondary"
          @click="$bvModal.hide('modal-cliente')"
          ><i class="fa fa-ban" /> Cancelar</b-btn
        >
      </div>
    </b-modal>
    <b-modal
      id="modal-wpp"
      hide-footer
      title="Enviar nota fiscal para whatsapp"
    >
      <b-form-group label="Número">
        <input
          class="form-control optional-custom-input"
          v-model="wppNumber"
          v-mask="['(##) ####-####', '(##) #####-####']"
          autocomplete="off"
          :id="`txtField${Math.random()}`"
          :name="`txtField${Math.random()}`"
          pattern="\d*"
        />
        <div class="text-center p-2">
          <hr />
          <b-alert
            :show="wppAlert.show"
            class="mt-2"
            :variant="wppAlert.type"
          >
            {{ wppAlert.message }}
          </b-alert>
          <b-btn
            variant="success"
            :disabled="enviandoWpp"
            @click="enviarWpp"
          >
            <b-spinner
              small
              size="sm"
              v-if="enviandoWpp"
            />
            <i class="fab fa-whatsapp"></i> Enviar</b-btn
          >
          <b-btn
            variant="secondary"
            @click="$bvModal.hide('modal-wpp')"
          >
            <b-spinner
              small
              size="sm"
              v-if="enviandoWpp"
            />
            <i class="fab fa-whatsapp"></i> Enviar</b-btn
          >
        </div>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
// import moment from "moment";
import PedidoLib from "../../libs/Pedido";
import FiscalLib from "../../libs/FiscalLib";
import ClienteForm from "../clientes/ClienteForm.vue";
import axios from "../../utils/axios";
import WhatsAppService from "../../libs/WhatsAppService";

export default {
  components: { ClienteForm },
  props: {
    MesaContaID: {
      type: Number,
      default: 0,
    },
    PedidoID: {
      type: Number,
      default: 0,
    },
    Pedido: {
      type: Object,
      default: null,
    },
    notaID: {
      type: Number,
      default: 0,
    },
    CodEmpresa: Number,
  },
  mounted() {
    console.log("fiscal open", this.PedidoID, this.Pedido, this.MesaContaID);
    if (!this.Pedido && this.PedidoID > 0) {
      this.carregar();
    }
    if (this.MesaContaID && this.MesaContaID > 0) {
      this.carregar();
    }
  },
  data() {
    return {
      loading: false,
      pedidoObj: null,
      mesaObj: null,
      emitindo: false,
      result: {
        show: false,
        success: null,
        message: "",
      },
      semCliente: true,
      cliente: {
        nome: "",
        telefone: "",
      },
      notaInfo: null,
      wppNumber: "",
      telefonesDisponiveis: [],
      enviandoWpp: false,
      wppAlert: {
        show: false,
        type: "success",
        message: "",
      },
    };
  },
  watch: {
    PedidoID: function () {
      this.carregar();
    },
    MesaContaID: function () {
      this.carregar();
    },
    notaID: function () {
      this.carregar();
    },
  },
  computed: {},
  methods: {
    async donwloadXML() {
      if (this.notaInfo && this.notaInfo.id > 0) {
        let xmlString;
        if (typeof this.notaInfo.xml === "string") {
          xmlString = this.notaInfo.xml;
        } else {
          // Assuming this.notaInfo.xml is an XML object
          const serializer = new XMLSerializer();
          xmlString = serializer.serializeToString(this.notaInfo.xml);
        }
        if (xmlString) {
          const base64String = btoa(unescape(encodeURIComponent(xmlString)));
          if (base64String) {
            const dataUrl = `data:application/xml;base64,${base64String}`;
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = `NFe_${this.notaInfo.nota_chave}.xml`;
            link.click();
          }
        }
      }
    },
    async desnvincularNota() {
      if (this.notaInfo && this.notaInfo.id > 0) {
        this.$swal
          .fire({
            title:
              "Você tem certeza que deseja desvincular a nota fiscal? Só será possível visualiza-la pelo menu de notas fiscais.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Desvincular",
            cancelButtonText: "Não",
          })
          .then(async (a) => {
            if (a.isConfirmed) {
              console.log("desvincular", this.notaInfo);
              let result = await FiscalLib.desvincularVenda(this.notaInfo.id);
              if (result && result.success === true) {
                this.$swal("Nota desvinculada do pedido!", "", "success");
                this.notaInfo = null;
                this.carregar();
              } else {
                this.$swal("Falha ao desvincular!", "", "error");
              }
            }
          });
      } else {
        this.$swal("Nota não vinculada!", "", "error");
      }
    },
    async getPDF() {
      //download pdf from getUrlDanfe() as base64 and show on iframe
      return `data:application/pdf;base64,${this.notaInfo.danfe}`;
    },
    async enviarWpp() {
      if (this.wppNumber.trim() == "" || this.wppNumber.length < 14) {
        this.wppAlert.show = true;
        this.wppAlert.type = "danger";
        this.wppAlert.message = "Informe um número válido";
        return;
      }
      this.enviandoWpp = true;

      await WhatsAppService.enviarTextoSimples(
        this.wppNumber,
        `*Nota fiscal emitida!*\n------------------------------------------\n\n*Chave:* ${this.notaInfo.nota_chave}\n\n\n------------------------------------------\n\nPARA CONSULTAR NO SITE DA RECEITA ABRA O LINK ABAIXO E PREENCHA COM A CHAVE DE ACESSO INFORMADA ACIMA\n
        \nhttps://www.nfce.fazenda.sp.gov.br/NFCeConsultaPublica/Paginas/ConsultaPublica.aspx`
      );
      let res = await WhatsAppService.enviarArquivo(
        this.wppNumber,
        this.notaInfo.danfeBASE64,
        this.notaInfo.nota_chave || "Cupom Fiscal"
      );

      let xmlString;
      if (typeof this.notaInfo.xml === "string") {
        xmlString = this.notaInfo.xml;
      } else {
        // Assuming this.notaInfo.xml is an XML object
        const serializer = new XMLSerializer();
        xmlString = serializer.serializeToString(this.notaInfo.xml);
      }

      // console.log("xml", xmlString);

      if (xmlString) {
        const base64String = btoa(unescape(encodeURIComponent(xmlString)));
        // console.log("base64String", base64String);
        if (base64String) {
          const dataUrl = `data:application/xml;base64,${base64String}`;

          await WhatsAppService.enviarArquivo(
            this.wppNumber,
            dataUrl,
            this.notaInfo.nota_chave || "Cupom Fiscal",
            "xml"
          );
        }
      }

      console.log("res wpp", res);

      this.enviandoWpp = false;
      this.wppAlert.show = true;
      this.wppAlert.type = "success";
      this.wppAlert.message = "Informações enviadas com sucesso!";
      this.$bvModal.hide("modal-wpp");

      // try {
    },
    async openSendWpp() {
      console.log(1);
      this.enviandoWpp = true;
      try {
        let config = await WhatsAppService.setConfiguration();
        console.log("config", config);
        if (config) {
          if (config.fiscal && config.fiscal.ativo) {
            this.$bvModal.show("modal-wpp");
            this.wppNumber = "16" + this.pedidoObj.cliente.Telefone;
          } else {
            this.$swal(
              "Envio de whatsapp desabilitado",
              "Verifique as configurações de envio para área FISCAL",
              "error"
            );
          }
        } else {
          this.$swal(
            "Envio de whatsapp desabilitado",
            "Verifique as configurações de envio para área FISCAL",
            "error"
          );
        }
      } catch (error) {
        alert("ERRO", error);
      }
      this.enviandoWpp = false;
    },
    openDanfe() {
      if (!this.notaInfo || this.notaInfo.status !== "Concluido") {
        this.$swal("Nota não concluida!", "", "error");
        return;
      }
      // window.open(this.getUrlDanfe());
      //open file in new tab from this.getUrlDanfe()
      window.open(this.getUrlDanfe(), "_blank");
    },
    getUrlDanfe() {
      //this.notaInfo.danfe is base64 for file, open it
      // return `data:application/pdf;base64,${this.notaInfo.danfe}`;
      return `${axios.defaults.baseURL.substring(
        0,
        axios.defaults.baseURL.length - 1
      )}${this.notaInfo.danfe}?token=${axios.defaults.headers["common"][
        "Authorization"
      ].replace("Bearer ", "")}`;
    },
    changeCliente(data) {
      this.cliente = data;
    },
    confirmarCliente() {
      this.$bvModal.show("modal-cliente");
    },
    async emitir() {
      let empresas = [];
      empresas = [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ];

      let empresa = empresas.find((x) => x.cod_empresa == this.CodEmpresa);
      console.log(empresa);
      if (
        !empresa ||
        !empresa.fiscal ||
        !empresa.fiscal.notaSaida ||
        !empresa.fiscal.notaSaida.tipoEmissao
      ) {
        this.result.success = false;
        this.result.message = "Serviço de emissão não habilitado!";
        this.result.show = true;
        return;
      }

      this.emitindo = true;
      this.$nextTick(async () => {
        try {
          // const delay = (delayInms) => {
          //   return new Promise((resolve) => setTimeout(resolve, delayInms));
          // };

          // await delay(5000);

          //this.result.success = false;
          //this.result.message = "Serviço de emissão não habilitado!";
          let res = await (empresa.fiscal.notaSaida.tipoEmissao == "Local"
            ? FiscalLib.emitirNotaLocal
            : FiscalLib.emitirNota)(
            this.PedidoID,
            this.cliente,
            this.MesaContaID
          );
          console.log("Result Emitir", res);
          if (res && res.success === true) {
            this.result.success = true;
            this.result.message = "Nota emitida com sucesso!";
            this.carregar();
            this.$bvModal.hide("modal-cliente");
          } else {
            this.result.success = false;
            this.result.message =
              typeof res.message === "string" ? res.message : res.message;
          }
        } catch (er) {
          this.result.success = false;
          this.result.message = "Falha ao emitir";
        }
        this.result.show = true;
        this.emitindo = false;
      });
    },
    async carregar() {
      // console.log("asd",this.MesaContaID, this.Pedido.ID)
      // if (this.MesaContaID > 0) {
      //   alert("Não habilitado!");
      //   this.$emit("close");
      // }
      this.loading = true;

      console.log("cupom ", this.PedidoID, this.MesaContaID);
      if (this.PedidoID > 0 || this.MesaContaID > 0) {
        let res = await PedidoLib.get(
          this.MesaContaID && this.MesaContaID > 0
            ? {
                salao: 1,
                id: this.MesaContaID,
                isCupom: true,
              }
            : {
                cod_pedido: this.PedidoID,
                cod_empresa: this.CodEmpresa,
              }
        );
        console.log("Res", res);
        if (res && res.success && res.data && res.data.length > 0) {
          if (this.MesaContaID && this.MesaContaID > 0) {
            // console.log("MesaConta", res);
            this.mesaObj = res.data[0];
            this.semCliente = true;
          } else {
            this.pedidoObj = res.data[0];
            this.semCliente =
              !this.pedidoObj ||
              !this.pedidoObj.cliente ||
              !this.pedidoObj.cliente.Telefone ||
              this.pedidoObj.cliente.Telefone == "" ||
              this.pedidoObj.cliente.Telefone == "01";
          }
          let buscaNota = await FiscalLib.buscarNota(
            this.MesaContaID && this.MesaContaID > 0
              ? {
                  "notas_fiscais.mesa_controle_id": this.MesaContaID,
                  // cod_empresa: this.pedidoObj.cod_empresa,
                }
              : {
                  "pedido.cod_pedido": this.pedidoObj.cod_pedido,
                  // cod_empresa: this.pedidoObj.cod_empresa,
                }
          );
          console.log("nota res", buscaNota);

          if (this.pedidoObj && !this.pedidoObj.cliente) {
            this.pedidoObj.cliente = {};
          }
          if (this.mesaObj && !this.mesaObj.cliente) {
            this.mesaObj.cliente = {};
          }
          if (buscaNota && buscaNota.success === true) {
            this.notaInfo = this.notaID
              ? buscaNota.data.find((x) => x.id == this.notaID)
              : buscaNota.data[buscaNota.data.length - 1];
            this.$forceUpdate();
          }
        } else {
          alert("Pedido não encontrado!");
          this.$emit("close");
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
